import React from 'react';
import TogiGame from './TogiGame';
import './App.css';

function App() {
  return (
    <div className="App">
      <h1>Togi's Adventure</h1>
      <h4>Help Togi and Walli navigate the neighborhood and collect Fancy Feast! Watch out for roaming cats and speeding cars.</h4>
      <p>Use the arrows to move Togi and Walli. Press spacebar to jump.</p>
      <TogiGame />
    </div>
  );
}

export default App;