import React, { useEffect, useRef } from 'react';
import Phaser from 'phaser';

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const TogiGame = () => {
  const gameRef = useRef(null);

  useEffect(() => {
    // Phaser game configuration (larger size: 1200x600)
    const config = {
        type: Phaser.AUTO,
        scale: {
          mode: Phaser.Scale.FIT,
          autoCenter: Phaser.Scale.CENTER_BOTH,
          width: isMobile ? window.innerWidth : 1200, 
          height: isMobile ? window.innerHeight : 600,
        },
        physics: {
          default: 'arcade',
          arcade: {
            gravity: { y: isMobile ? 800 : 1000 }, // Lower gravity for mobile
            debug: false,
          },
        },
        parent: 'phaser-game',
        scene: {
          preload: preload,
          create: create,
          update: update,
        },
      };

    let game;
    let player, walli, cursors, spaceKey, score = 0, scoreText, obstacles, collectibles, gameState = 'title';

    let bgMusic;

    console.log('isMobile', isMobile);
    

    function preload() {
      this.load.image('background', '/assets/neighborhood.jpg');
      this.load.image('togi', '/assets/togi.png');
      this.load.image('walli', '/assets/walli.png');
      this.load.image('cat', '/assets/cat.png');
      this.load.image('car', '/assets/car.png');
      this.load.image('fancy_feast', '/assets/fancy_feast.png');
      this.load.audio('bgMusic', '/assets/background-music.mp3'); // Adjust path to your MP3 file
      
      this.load.audio('bgMusic', '/assets/background-music.mp3'); 
    }

    function create() {

        if (!bgMusic) { // Only create music if it hasn't been created
            bgMusic = this.sound.add('bgMusic', { loop: true, volume: 0.5 });
            bgMusic.play();
          }

          if (isMobile) {
            this.physics.world.setFPS(30); // Reduce FPS for smoother mobile performance
          }

          let groundHeight = isMobile ? window.innerHeight * 0.9 : 590; // Adjust ground position

      // Add background (scale to fit larger canvas)
      let background = this.add.image(600, 300, 'background').setScrollFactor(0);
      background.setDisplaySize(1200, 600); // Scale background to match new size


        if (isMobile) {
            player = this.physics.add.sprite(150, groundHeight - 50, 'togi');
            player.setScale(1.5); // Smaller Togi for mobile
            player.setSize(40, 30);

            walli = this.physics.add.sprite(100, groundHeight - 50, 'walli');
            walli.setScale(isMobile ? 1.4 : 1.9);
            walli.setSize(isMobile ? 35 : 45, 45);
        } else {
            // Add player (Togi) - larger sprite size
            player = this.physics.add.sprite(150, 555, 'togi'); // Adjusted starting position
            player.setCollideWorldBounds(true);
            player.setScale(2.0); // Scale up Togi
            player.setSize(54, 30); // Match physics body to scaled sprite
            player.setOffset(0, 30); // Adjust offset if needed

            // Add Walli (sidekick) - larger sprite size
            walli = this.physics.add.sprite(100, 555, 'walli'); // Adjusted starting position
            walli.setCollideWorldBounds(true);
            walli.setScale(1.9); // Scale up Walli
            walli.setSize(45, 45); // Match physics body to scaled sprite
        }

        let ground = this.physics.add.staticGroup();

        if (isMobile) {
            ground.create(window.innerWidth / 2, groundHeight, null)
                .setSize(window.innerWidth, 20)
                .setOrigin(0.5, 0.5);
        }

      // Add ground (invisible static platform) - larger scale
      //let ground = this.physics.add.staticGroup();
      //let groundObject = ground.create(600, 590, null).setSize(1200, 20).setOrigin(0.5, 0.5);
      this.physics.add.collider(player, ground);
      this.physics.add.collider(walli, ground);

      // Enable collisions
      this.physics.add.collider(player, ground);
      this.physics.add.collider(walli, ground);

      // Keyboard controls (use spacebar for jump instead of up arrow)
      cursors = this.input.keyboard.createCursorKeys();
      spaceKey = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE); // Add spacebar key

      // Obstacles and collectibles (groups)
      obstacles = this.physics.add.group();
      collectibles = this.physics.add.group();

      // Score text - larger font
      scoreText = this.add.text(5, 5, 'Score: 0', { fontSize: '20px', fill: 'white' });

      // Title screen
      if (gameState === 'title') {
        let title = this.add.text(600, 300, "Togi's Adventure\nPress SPACE to Start", { fontSize: '48px', fill: 'white' });
        title.setOrigin(0.5);
        this.input.keyboard.on('keydown-SPACE', () => {
          title.destroy();
          gameState = 'playing';
          startGame.call(this);
        });
      }
    }

    function startGame() {
        scheduleNextObstacle.call(this);
        
        /*
      this.time.addEvent({
        delay: 5000,
        callback: () => spawnObstacle.call(this),
        loop: true,
      });
      */
      
      this.time.addEvent({
        delay: 5000, // 2 seconds
        callback: () => spawnCollectible.call(this),
        loop: true,
      });
    }

    function scheduleNextObstacle() {
        let delay = Phaser.Math.Between(3000, 8000); // Random delay between 3s and 8s

        this.time.addEvent({
            delay: delay,
            callback: () => {
            spawnObstacle.call(this);
            scheduleNextObstacle.call(this); // Recursively schedule next spawn
            },
            loop: false, // Only run once, then reschedule
        });
      }
      

    function spawnObstacle() {
        console.log('Spawning obstacle');
        /*
      let type = Phaser.Math.Between(0, 1) ? 'cat' : 'car';
      let obstacle = obstacles.create(1200, 550, type); // Adjusted spawn position for larger canvas
      obstacle.setVelocityX(-300); // Increased scroll speed for larger canvas
      obstacle.setCollideWorldBounds(false);
      obstacle.setScale(1.5); // Scale up obstacles
      obstacle.setSize(type === 'car' ? 90 : 60, 60); // Adjust physics size for scaled obstacles
      */
      let type = Phaser.Math.Between(0, 1) ? 'cat' : 'car';

      let randomSize = Phaser.Math.FloatBetween(1.0, 2.0); // Random size between 1.0 and 2.0

        // Calculate adjusted spawn height based on scale
        let baseY = type === 'car' ? 580 : 565; // Base position at normal scale
        let adjustedY = baseY - (randomSize - 1) * 20; // Adjust Y placement dynamically

        let obstacle = obstacles.create(1200, adjustedY, type);
      /*
      let randomSize = Phaser.Math.Between(1.0, 2.0);

        // Create obstacle at the correct height (just above the ground level)
        let obstacle = obstacles.create(1200, type === 'car' ? 580 : 565, type);
        let obstacle = obstacles.create(1200, type === 'car' ? 567 : 538, type);
        */

        let randomSpeed = Phaser.Math.Between(-250, -500); // Random speed between -250 and -100

        obstacle.setVelocityX(randomSpeed); // Randomized speed moving left
        obstacle.setCollideWorldBounds(false);
        
        obstacle.setScale(randomSize);
        obstacle.setSize(type === 'car' ? 90 : 36, type === 'car' ? 29 : 60); // Adjust physics size

        // Ensure obstacles stay on the ground by disabling gravity
        obstacle.body.setAllowGravity(false);

    }

    function spawnCollectible() {
      let collectible = collectibles.create(1200, Phaser.Math.Between(50, 550), 'fancy_feast'); // Adjusted y range
      let randomSpeed = Phaser.Math.Between(-250, -2000); // Random speed between -250 and -100
      collectible.setVelocityX(randomSpeed); // Increased scroll speed for larger canvas
      collectible.setCollideWorldBounds(false);
      collectible.setScale(1.5); // Scale up collectibles
      collectible.setSize(70, 50); // Adjust physics size for scaled collectible
    }

    function update() {
      if (gameState !== 'playing') return;



      if (isMobile) {
        this.input.on('pointerdown', (pointer) => {
          if (pointer.x < window.innerWidth / 2) {
            player.setVelocityX(-200); // Move left
          } else {
            player.setVelocityX(200); // Move right
          }
        });
      
        this.input.on('pointerup', () => {
          player.setVelocityX(0); // Stop movement
        });
      
        // Tap anywhere to jump
        this.input.on('pointerdown', () => {
          if (player.body.blocked.down) {
            player.setVelocityY(-400); // Lower jump power for mobile
            walli.setVelocityY(-400);
          }
        });
      }

      // Player movement (adjusted velocity for larger scale)
      if (cursors.left.isDown) {
        player.setVelocityX(-300);
      } else if (cursors.right.isDown) {
        player.setVelocityX(300);
      } else {
        player.setVelocityX(0);
      }

      // Jump with spacebar
      if (spaceKey.isDown && player.body.blocked.down) {
        console.log('Jumping');
        player.setVelocityY(-650);
        walli.setVelocityY(-650);
      }
    
    // Ensure Togi lands on the ground
    if (player.body.touching.down) {
        //player.setY(555); // Reset Y position to ground level
    }

      // Walli follows Togi
      walli.setX(player.x - 75); // Adjusted distance for larger scale

      // Check collisions with obstacles
      this.physics.world.overlap(player, obstacles, (player, obstacle) => {
        if (player.body.velocity.y > 0 && player.y + player.height / 2 < obstacle.y) {
          // If Togi is falling and lands on top, remove the obstacle
          obstacle.destroy();
        } else {
          // Otherwise, trigger Game Over
          gameState = 'gameOver';
          showGameOver.call(this);
        }
      });
      // Check collisions with collectibles
      this.physics.world.overlap(player, collectibles, (player, collectible) => {
        collectible.destroy();
        score += 10;
        if (Math.abs(player.x - walli.x) < 150) score += 5; // Adjusted Walli bonus distance
        scoreText.setText('Score: ' + score);
      });

      // Remove off-screen objects
      obstacles.getChildren().forEach(obstacle => {
        if (obstacle.x < -50) obstacle.destroy();
      });
      collectibles.getChildren().forEach(collectible => {
        if (collectible.x < -50) collectible.destroy();
      });
    }

    function showGameOver() {
      this.physics.pause();
      let gameOverText = this.add.text(600, 300, `Game Over!\nFinal Score: ${score}\nPress R to Restart`, { fontSize: '48px', fill: 'white', align: 'center' });
      gameOverText.setOrigin(0.5);
      this.input.keyboard.on('keydown-R', () => {
        gameOverText.destroy();
        this.scene.restart();
        gameState = 'title';
        score = 0;
        obstacles.clear();
        collectibles.clear();
      });
    }

    // Initialize Phaser game
    game = new Phaser.Game(config);

    // Cleanup on unmount
    return () => {
      if (game) {
        game.destroy(true);
      }
    };
  }, []); // Empty dependency array ensures effect runs once on mount

  return <div id="phaser-game" style={{ width: '1200px', height: '600px', margin: '0 auto' }} />;
};

export default TogiGame;